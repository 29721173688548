<template>
  <app-tabs v-model="active">
    <el-tab-pane label="预约列表" lazy>
      <list-a />
    </el-tab-pane>
    <el-tab-pane label="临时预约" lazy>
      <list-temp />
    </el-tab-pane>
  </app-tabs>
</template>

<script>
import listA from './listA';
import listTemp from './listTemp';

export default {
  name: 'Setting',
  components: { listA, listTemp },
  data() {
    return {
      active: '0'
    };
  }
};
</script>
